// @flow
import React from "react";
import {
    RequestDefineReason as _DefineReason,
    Layout,
    CreateRequestLayout,
    withPrivateRoute,
} from "@containers";

/**
 * Reasons Page
 */
const DefineReason = (props: *) => (
    <Layout {...props}>
        <CreateRequestLayout>
            <_DefineReason />
        </CreateRequestLayout>
    </Layout>
);

export default withPrivateRoute(DefineReason);
